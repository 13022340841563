<template lang="pug">
  td
    a(v-if="item.course").course-info-main.course-hover {{city}} {{ item.course.date || '' }}
</template>

<script>
import { mapGetters } from 'vuex'
import { CANDIDATE_PAGE } from '../../candidates/core/candidates-const'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      cities: 'crmCourseCities/items'
    }),

    city() {
      if (!this.cities.length) return null
      let city = this.cities.find(city => city.ID === this.item.course.city)
      return city.name
    }
  }
}
</script>

<style lang="scss" scoped>
.course-info-main {
  font-weight: bold;
  color: $title-second;
  display: block;
  text-decoration: none;
}

.course-info-additional {
  color: $label-color;
  display: block;
  text-decoration: none;
}

.course-hover {
  &:hover {
    color: $primary-color;
  }
}
</style>
