<template lang="pug">
  tr(:key="index" data-test="candidate-row" :class="['course-row', {'updated' : item.animation, 'app-table__row_expanded': isExpanded}]" :style="{'background-color': candidateColor}")
    td.col--item-page.app-table__cell.app-table__cell_small
      .d-flex
        v-icon(
          :class="{'expanded': isExpanded}"
          @click="$emit('onGoToEdit', {expand, isExpanded})"
          data-test="candidate-open"
        ).col--candidate-item mdi-chevron-right
    td.col--position {{ positionInList }}
    td.col--photo
      upload-photo(:ID="item.ID" :course-photo="item.course_photo" :fullname="item.candidateName")
    candidate-reserved-info(
      :item="item"
      :courseDate="courseDate"
      reserved
      small
    )
    td.col--presentie
      presentie-status(
        :journey="item.journey_day.id"
        :presentie-status="item.presentie_status"
        :exam="item.exam.id"
        :candidateID="item.ID"
      )
    td.col--name(data-test="candidate-name" :class="{'was-changed': item.exam.journey_was_changed}") {{ item.candidateName }}
      span.phone {{ item.candidateTel }}
    td.col--code.text-center
      span.code {{ item.exam.code }}
    td.col--results(:style="{backgroundColor: colorStatus}" :class="{ 'loaded-not-seen': loadedNotSeen }")
      result-service-provider
        span.text-center
          result-info(v-if="!isMijnReservation" :reservedExam="item.result" :isMijnReservation="isMijnReservation" :candidateName="item.candidateName")
          mijn-info(v-if="isMijnReservation" :reservedExam="item.result" :candidateName="item.candidateName")
    td.col--status
      v-chip(v-if="item.result.result" :color="statusChipColor" small dark) {{ item.result.result.status }}
      v-chip(v-else color="black" small dark) Not fetched
    td.col--send-results
      candidate-results-email(:item="item")
    td.col-afezig
      inform-candidate(:ID="item.exam.id" :messageSentCount="item.exam.message_sent_count")
    td.col--will-come
      willCome(:will_come_to_course="item.willComeToCourse" @update="updateCandidateWillCome")
    td.col--presentie
      photo-consent(
        :coursePhotoConsent="item.course_photo_consent"
        :candidateID="item.ID"
      )
    td.col--appID {{ item.applicationId || '-' }}
    td.col--type
      v-chip(small :color="PURPLE" v-if="item.isMijnReservation").text-bold.white--text Mijn
      v-chip(small color="amber lighten-1" v-else).text-bold.white--text Top
    td.col--product
      div
        template(v-if="isPastTab") {{ item.exam.productName }}
        template(v-else) {{ item.productName }}
    td.col--course-type {{ item.course_type || '-' }}
    td.col--notes
      note(:value="item.notes" :ID="item.ID")
    td.col--color
      candidates-service
        candidate-color(:activeItems="[item]" :page="COLOR_PAGE")
</template>

<script>
import CandidatesService from "@/app/admin/modules/candidates/core/candidates-service"
import copyMixin from "@/mixins/copy.mixin"
import checkBoolean from "@/components/global/CheckBoolean.vue"
import { COLOR_PAGE } from '@/util/const'
import colorMixin from '@/mixins/color.mixin'
import CoursesInfo from "@/app/admin/modules/course_managment/components/CMCourses.vue"
import isRefundedMixin from "@/mixins/isRefunded.mixin"
import CheckColor from "@/components/global/CheckColor.vue"
import rowMixinGlobal from "@/app/admin/modules/candidates/core/candidateGlobalRow.mixin"
import { COLOR_STATUS, PURPLE } from '../../../results/core/results-const'
import { RESULT_STATUS } from '../../../results/core/models/resultReservedExam'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'
import { COURSE_DAY_SIDEBAR_TABS } from '../../core/courseDay-const'

export default {
  mixins: [copyMixin, colorMixin, isRefundedMixin, rowMixinGlobal, featureFlagsMixin],

  inject: ['svc'],

  props: {
    item: {
      type: Object,
      requred: true
    },
    index: Number,
    isSelected: Boolean,
    select: Function,
    expand: Function,
    isExpanded: Boolean,
    activeItems: Array,
    labels: Array,
    courseDate: String,
    primaryPagination: Object
  },

  data: () => ({
    svc: new CandidatesService(),
    COLOR_PAGE: COLOR_PAGE.CANDIDATES,
    PURPLE
  }),

  computed: {
    positionInList() {
      return (this.index + 1) + (this.primaryPagination.page - 1) * this.primaryPagination.size;
    },

    // it's fix for 3cx plugin on chrome
    candidateNumberSplicedOnTwoElement() {
      let number = this.item.candidateNumber + ''
      let numberArr = number.split('')
      let halfLength = Math.ceil(numberArr.length / 2);
      let leftSide = numberArr.splice(0, halfLength);
      let html = `<span>${leftSide.join('')}</span><span>${numberArr.join('')}</span>`
      return html
    },

    cbrAccount() {
      return this.$store.getters['cbrUser/getNameByID'](this.item.accountUsername)
    },

    isMijnReservation() {
      // TODO: NEED IS MIJN FROM BACKEND
      return this.item.isMijnReservation
    },

    colorStatus() {
      if (!this.item.result) return null
      if (this.item.result && this.item.result.ignore_results) return COLOR_STATUS.IGNORE_RESUTLS
      if (this.item.result.result && this.item.result.result.has_zero_mistakes) return COLOR_STATUS.ZERO_MISTAKES
      if (this.item.result.result && this.item.result.result.has_passed) return COLOR_STATUS.HAS_PASSED
      if (this.item.result.result && this.item.result.result.has_not_came) return COLOR_STATUS.HAS_NOT_CAME
      if (!this.loadedSeen) return null
      if (this.item.result.result && !this.item.result.result.has_passed) return COLOR_STATUS.NOT_HAS_PASSED
      return null
    },

    statusChipColor() {
      if (!this.item.result.result) return 'black'
      if (this.item.result.result.status === RESULT_STATUS.LOADED) return 'success'
      if (this.item.result.result.status === RESULT_STATUS.FAILED) return 'error'
      if (this.item.result.result.status === RESULT_STATUS.FETCHING) return 'warning'
      return 'black'
    },

    loadedSeen() {
      if (!this.item.result.result) return false
      return !!(this.item.result.result.status === RESULT_STATUS.LOADED && this.item.result.result.seen);
    },

    loadedNotSeen() {
      if (!this.item.result.result) return false
      return this.item.result.result.status === RESULT_STATUS.LOADED && !this.item.result.result.seen;
    },

    isPastTab() {
      return this.$route.query.tab_name === COURSE_DAY_SIDEBAR_TABS.PAST
    }
  },

  methods: {
    async updateCandidateWillCome(value) {
      await this.svc.updateWillCome(this.item.ID, value)
      this.$emit('onWillComeUpdate', {item: this.item, index: this.index})
    },

    setColorId(colorId) {
      this.candidateColorID = colorId;
    },

    copyData(model) {
      let data = model.getSpreadsheetData(this.$store)
      this.copy(data)
    },

    selectWithShiftkey({event, item, index}) {
      if (!this.activeItems.length || !event.shiftKey) return
      this.$emit('selectedWithShiftkey', {item: item, index: index})
    },
  },

  provide: function () {
    return {
      svc: () => this.svc
    }
  },

  components: {
    reservationDate: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesReservedDate.vue'),
    paymentsLog: () => import('@/app/admin/modules/candidates/components/payments/CandidatesPaymentLog.vue'),
    checkColor: CheckColor,
    templatesLog: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesETempLog.vue'),
    note: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesNote.vue'),
    courseConfirmed: () => import('@/app/admin/modules/candidates/components/candidates/CandidateCourseConfirmed.vue'),
    candidateColor: () => import('@/app/admin/modules/candidates/components/CandidateColor.vue'),
    willCome: () => import('@/app/admin/modules/course_managment/components/СMWillCome.vue'),
    resultInfo: () => import ("../CourseDayCandidateExamInfo.vue"),
    mijnInfo: () => import ("../CourseDayMijnIExam.vue"),
    resultServiceProvider: () => import('@/app/admin/modules/results/components/ResultsServiceProvider.vue'),
    candidatesService: () => import('@/app/admin/modules/candidates/components/CandidatesProvider.vue'),
    presentieStatus: () => import('../journey/CourseDayPresentieStatus.vue'),
    transportIcon: () => import("@/components/global/TransportIcon.vue"),
    transportConfig: () => import('../../../course_day/components/CourseDayBulkTranportConfig.vue'),
    candidateResultsEmail: () => import('@/app/admin/modules/candidates/components/candidates/CandidateResultsEmail.vue'),
    informCandidate: () => import('../../../results/components/ResultsInformCandidate.vue'),
    candidateReservedInfo: () => import('../body/CandidateReservedInfo.vue'),
    photoConsent: () => import('../CourseDayPhotoConsent.vue'),

    uploadPhoto: () => import('../actions/UploadPhoto.vue'),
    coursesInfo: CoursesInfo,
    checkBoolean
  }
}
</script>

<style lang="scss" scoped>
.updated {
  background-color: rgba($success-color, 0.3) !important;
}

.expanded {
  color: $primary-color !important;
  transform: rotate(90deg);
}

.table-dates {
  width: 170px;
  line-height: 1.3;
}

.table-reservation {
  margin: 0 -4px;
}

.app-table td {
  font-size: 12px;
  line-height: 1.3;
}

.app-table__row_expanded {
  > td {
    border-top: 1px solid $primary-color;

    &:first-child {
      border-left: 1px solid $primary-color;
    }

    &:last-child {
      border-right: 1px solid $primary-color;
    }
  }
}

.white-space-normal {
  white-space: normal;
}

.was-changed {
  background-color: #73C8FAD1;
}

.col {
  &-- {
    &item-page {
      transform: scale(0.8);
    }
    &photo {
      min-width: 30px;
      max-width: 30px;
      width: 30px;
    }


    &position {
      max-width: 22px;
      width: 22px;
    }


    &name {
      min-width: 100px;
      max-width: 100px;

      @include media("<=tablet") {
        min-width: 55px;
        max-width: 55px;
        width: 55px;
        font-size: 11px !important;
      }

      .phone {
        display: block;
        font-size: 11px;

        overflow-wrap: break-word;
      }
    }

    &code {
      .code {
        word-break: break-all;
      }
    }
    &results {
      text-align: center;

      &.loaded-not-seen {
        background-color: #CCCCCC !important;
      }
    }
    &status {
      text-align: center;
    }
    &send-results {
      text-align: center;
    }
    &will-come {
      cursor: pointer;
    }
    &appID {
      max-width: 100px;
      text-align: center;
    }
    &product {
      min-width: 100px;
      max-width: 120px;
      text-align: center;
    }
    &photo {
      text-align: center;
    }

    &cbr {
      max-width: 100px;
    }
    &payment {
      min-width: 75px;
      max-width: 100px;

      &.has-package {
        background-color: #9DF397;
      }

      &.not-has-package {
        background-color: #F39797;
      }
    }
    &transport-config {
      min-width: 100px;
      max-width: 150px;

      .transport-wrapper {
        cursor: pointer;
      }
    }
    &course-type {
      max-width: 80px;
    }


    &notes {
      max-width: 150px;
    }
  }
}

</style>

<style lang="scss">
.course-row {
  td {
    border: none !important;
  }
}

</style>
